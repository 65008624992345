<template>
  <el-dialog
    id="OrgsAliasesDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    @keydown.f4.native="selectCorp"
    @keydown.f6.native="setAsCorp"
    @keydown.f7.native="clearAlias"
    @keydown.f8.native="selectSignatory"
    @keydown.f9.native="showAliasesHistory"
    destroy-on-close
  >
    <el-header class="header2" height="48"
      ><el-row type="flex" justify="start" align="center" style="padding-left:11px">
        <TSeparator />
        <TIButton
          icon="select_corp_blue"
          @onClick="selectCorp"
          :label="$t('Вказати корпорацію {shortcut}', { shortcut: '(F4)' })"
          :disabled="!selectedRows || !selectedRows.length"
        />
        <TIButton
          icon="set_new_corp_blue"
          @onClick="setAsCorp"
          :label="$t('Назначити окремою {shortcut}', { shortcut: '(F6)' })"
          :disabled="isAllowSelectedOrgSetAsCorp"
        />
        <TIButton icon="delete_red" @onClick="clearAlias" :label="$t('Зкинути перенаправлення {shortcut}', { shortcut: '(F7)' })" :disabled="isAllowClear" />
        <TSeparator />
        <TIButton
          icon="persons_green"
          @onClick="selectSignatory"
          :label="$t('Підписант {shortcut}', { shortcut: '(F8)' })"
          :disabled="!selectedRows || !selectedRows.length"
        />
        <TSeparator />
        <TIButton
          icon="clock_carrot"
          @onClick="showAliasesHistory"
          :label="$t('Історія змін {shortcut}', { shortcut: '(F9)' })"
          :disabled="!selectedRows || !selectedRows.length"
        />
        <TSeparator />
      </el-row>
    </el-header>
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              placeholder="Пошук"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="resetFilter($event, this)"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="orgsAliasesTable"
          id="orgsAliasesTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :treeData="true"
          :rowData="orgsTree"
          :gridOptions="gridOptions"
          :getDataPath="getDataPath"
          :autoGroupColumnDef="autoGroupColumnDef"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
    <SelectOrgAlias bus-event="orgAliases.forms.select.corp" @select-commit="onSelectCorpCommit" />
    <OrgAliasHistory bus-event="orgAliases.forms.history" />
    <Signatory bus-event="orgAliases.forms.signatory" @select-commit="onSelectSignatoryCommit" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "OrgsAliases",
  components: {
    AgGridVue,
    Icon,
    TIButton,
    TSeparator,
    SelectOrgAlias: () => import("@/components/forms/orgsAliases/SelectOrgAlias"),
    OrgAliasHistory: () => import("@/components/forms/orgsAliases/OrgAliasHistory"),
    Signatory: () => import("@/components/forms/Signatory"),
    GridCellWithIcons,
  },
  data() {
    var that = this;
    return {
      frameworkComponents: null,
      empty: [],
      loading: false,
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Керування юр. особами"),
      target: null,
      model: {},
      filterString: "",
      getDataPath: (data) => {
        return data.path;
      },
      filterString: "",
      selectedRows: null,
      selectedParentRules: [],
      waitForAnswer: false,
      keyUpTimer: null,
      filterHasChanged: false,
      gridApi: null,
      gridColumnApi: null,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        headerTooltip: $tt("Назва"),
        sort: "asc",
        field: "name",
        pinned: "left",
        cellRendererParams: {
          suppressCount: true,
          checkbox: false,
        },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
          if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
          return valueA > valueB ? 1 : -1;
        },
      },

      columns: [
        {
          headerName: $tt("Перенаправлення"),
          field: "isAlias",
          width: "150px",
          flex: 1,
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "info-circle",
              type: "fas el-icon-fa-",
              color: "blue",
              iconClass: "icon-action",
              title: function(params) {
                return $tt("Історія перенаправлень");
              },
              onClick: (params) => {
                this.showAliasesHistory(null, params);
              },
            },
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: $tt("Зкиниути перенаправлення"),
              show: function(params) {
                return params.data.historyCount && params.data.historyCount > 0;
              },
              onClick: (params) => {
                this.clearAlias(null, params);
              },
            },
          ],
          valueGetter: (params) => {
            return params && params.data && params.data.historyCount ? $tt("Активне") : "";
          },
        },
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
        },
        {
          headerName: $tt("Підписант"),
          field: "signatory",
          width: "150px",
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "edit",
              type: "fas el-icon-fa-",
              color: "green",
              iconClass: "icon-action",
              // show: function(params) {
              //   return params.data.signatory;
              // },
              title: function(params) {
                return $tt("Редагувати підписанта");
              },
              onClick: (params) => {
                this.selectSignatory(null, params);
              },
            },
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: $tt("Видалити підписанта"),
              show: function(params) {
                return params.data.signatory;
              },
              onClick: (params) => {
                this.onClearSignatory(null, params);
              },
            },
          ],
          valueGetter(params) {
            if (params.data.signatory) {
              return params.data.signatory.name;
            } else return "";
          },
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.frameworkComponents = { GridCellWithIcons: GridCellWithIcons };
  },
  created() {
    var that = this;
    bus.$on("forms.orgs.aliases.show", (data) => {
      if (!that.dialogShow) {
        that.title = (data && data.title) || that.title;
        that.filterString = "";
        that.initModel = _.extend({}, data ? data : {});
        that.loading = true;
        that.dialogShow = true;
      }
    });
    bus.$on("forms.orgs.aliases.hide", () => {
      that.dialogShow = false;
      that.resizeObserver = null;
    });
  },
  computed: {
    orgsTree() {
      return this.$store.getters["orgs/orgsAndAliases"](this.filterString);
    },
    isAllowSelectedOrgSetAsCorp() {
      if (this.selectedRows && this.selectedRows.length) {
        var data = this.selectedRows[0];
        if (data.path.length > 1) return false;
        else return true;
      }
      return true;
    },
    isAllowClear() {
      if (this.selectedRows && this.selectedRows.length) {
        var data = this.selectedRows[0];
        return !data.historyCount || data.historyCount == 0;
      }
      return true;
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 48 - 36;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("orgsAliasesGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        this.$refs.filter.clear();
      }
    },
    initSelection() {},
    onOpen() {
      this.observeHeight();
    },
    onClose() {
      this.unobserveHeight();
    },
    onOpened() {
      var that = this;
      this.loading = true;
      this.$store
        .dispatch("orgs/getAliases")
        .then(() => {
          //that.initSelection();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCommit() {
      this.dialogShow = false;
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onFormEnter() {},
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { orgsAliasesGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    selectCorp() {
      if (this.selectedRows && this.selectedRows.length) {
        bus.$emit("orgAliases.forms.select.corp.show", { title: $tt("Вибір корпорації"), axiomaId: this.selectedRows[0].axiomaId });
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
    showAliasesHistory(env, params) {
      var corp = null;
      if (params) {
        corp = params.data;
      } else if (this.selectedRows && this.selectedRows.length) {
        corp = this.selectedRows[0];
      }
      if (corp) {
        bus.$emit("orgAliases.forms.history.show", { title: $tt("Історія змін {corp}", { corp: corp.name }), axiomaId: corp.axiomaId });
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
    selectSignatory(env, params) {
      var corp = null;
      if (params) {
        corp = params.data;
      } else if (this.selectedRows && this.selectedRows.length) {
        corp = this.selectedRows[0];
      }
      if (corp) {
        bus.$emit("orgAliases.forms.signatory.show", {
          title: $tt("Керування підписантами"),
          selectedSignatory: corp.signatory ? corp.signatory._id : null,
          axiomaId: corp.axiomaId,
          orgName: corp.name,
        });
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
    onSelectSignatoryCommit(data) {
      var corp = null;
      if (data.axiomaId) {
        var node = this.gridOptions.api.getRowNode(data.axiomaId);
        if (node) {
          corp = node.data;
        }
      }
      if (corp && data) {
        // set
        if (data.result && data.result.length) {
          this.$store
            .dispatch("orgs/assignSignatory", { axiomaId: corp.axiomaId, signatoryId: data.result[0]._id, signatory: data.result[0] })
            .finally()
            .then(() => {
              var node = this.gridOptions.api.getRowNode(corp.axiomaId);
              this.gridOptions.api.refreshCells({ rowNodes: [node], force: true });
              this.$message.success($tt("Структуру змінено!"));
            })
            .catch((err) => {
              this.loading = false;
              globalErrorMessage($tt("Помилка"), err);
            });
        }
        // clear
        else {
          this.$store
            .dispatch("orgs/clearSignatory", { axiomaId: corp.axiomaId })
            .finally()
            .then(() => {
              var node = this.gridOptions.api.getRowNode(corp.axiomaId);
              this.gridOptions.api.refreshCells({ rowNodes: [node], force: true });
              this.$message.success($tt("Структуру змінено!"));
            })
            .catch((err) => {
              this.loading = false;
              globalErrorMessage($tt("Помилка"), err);
            });
        }
      }
    },
    onClearSignatory(event, params) {
      var corp = null;
      if (params) {
        corp = params.data;
      }
      if (corp) {
        this.$confirm(
          $tt("Ви дійсно бажаєте видалити підписанта [{name}] для [{corp}]?", { name: params.data.signatory.name, corp: params.data.name }),
          $tt("Увага"),
          {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          }
        )
          .then(() => {
            this.$store
              .dispatch("orgs/clearSignatory", { axiomaId: corp.axiomaId })
              .finally()
              .then(() => {
                var node = this.gridOptions.api.getRowNode(corp.axiomaId);
                this.gridOptions.api.refreshCells({ rowNodes: [node], force: true });
                this.$message.success($tt("Структуру змінено!"));
              })
              .catch((err) => {
                this.loading = false;
                globalErrorMessage($tt("Помилка"), err);
              });
          })
          .catch(() => {});
      }
    },
    onSelectCorpCommit(data) {
      if (this.selectedRows && this.selectedRows.length) {
        this.loading = true;
        this.$store
          .dispatch("orgs/addAlias", { axiomaId: this.selectedRows[0].axiomaId, parentAxiomaId: data.axiomaId })
          .finally(() => {})
          .then(() => {
            this.$store
              .dispatch("orgs/getAliases")
              .finally(() => (this.loading = false))
              .then(() => {
                var fromNode = this.gridOptions.api.getRowNode(this.selectedRows[0].axiomaId);
                var toNode = this.gridOptions.api.getRowNode(data.axiomaId);
                this.gridOptions.api.refreshCells({ rowNodes: [fromNode, toNode], force: true });
                this.$message.success($tt("Структуру змінено!"));
              });
          })
          .catch((err) => {
            this.loading = false;
            globalErrorMessage($tt("Помилка"), err);
          });
      }
    },
    setAsCorp() {
      if (this.selectedRows && this.selectedRows.length) {
        var data = this.selectedRows[0];
        this.$confirm($tt("Ви дійсно бажаєте позначити [{name}] як корпорацію?", { name: data.name }), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.$store
              .dispatch("orgs/addAlias", { axiomaId: data.axiomaId, parentAxiomaId: 0 })
              .finally(() => {})
              .then(() => {
                this.$store
                  .dispatch("orgs/getAliases")
                  .finally(() => (this.loading = false))
                  .then(() => {
                    var nodes = [];
                    if (data.parentId != 0) nodes.push(this.gridOptions.api.getRowNode(data.parentId));
                    nodes.push(this.gridOptions.api.getRowNode(data.axiomaId));
                    if (nodes.length) this.gridOptions.api.refreshCells({ rowNodes: nodes, force: true });
                    this.$message.success($tt("Структуру змінено!"));
                  });
              })
              .catch((err) => {
                this.loading = false;
                globalErrorMessage($tt("Помилка"), err);
              });
          })
          .catch(() => {});
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
    clearAlias(env, params) {
      var corp = null;
      if (params) {
        corp = params.data;
      } else if (this.selectedRows && this.selectedRows.length) {
        corp = this.selectedRows[0];
      }
      if (corp) {
        this.$confirm($tt("Ви дійсно бажаєте видалити перенаправлення для [{name}]?", { name: corp.name }), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.$store
              .dispatch("orgs/clearAlias", { axiomaId: corp.axiomaId })
              .finally(() => {})
              .then(() => {
                this.$store
                  .dispatch("orgs/getAliases")
                  .finally(() => (this.loading = false))
                  .then(() => {
                    var node = this.gridOptions.api.getRowNode(corp.axiomaId);
                    this.gridOptions.api.refreshCells({ rowNodes: [node, params.node], force: true });
                    this.$message.success($tt("Структуру змінено!"));
                  });
              })
              .catch((err) => {
                this.loading = false;
                globalErrorMessage($tt("Помилка"), err);
              });
          })
          .catch(() => {});
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
  },
};
</script>

<style lang="scss" scope>
#OrgsAliasesDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 6px !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#OrgsAliasesDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
